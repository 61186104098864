<template>
  <div class="email-input">
    <input type="text" class="gt-input email-input__id" :value="formState.id" @input="onChange" name="id" />
    <span class="email-input__at">@</span>
    <select class="gt-input email-input__select" :value="formState.domain" @change="onChange" name="domain">
      <option v-for="domain in emailDomains" :value="domain" :key="domain">{{ domain }}</option>
    </select>
    <input
      type="text"
      class="gt-input email-input__direct"
      v-if="formState.domain === '직접 입력'"
      :value="formState.text"
      @input="onChange"
      name="text"
    />
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'formState',
    event: 'change',
  },
  props: {
    formState: {
      type: Object,
    },
  },
  data() {
    return {
      // TODO 공통
      emailDomains: [
        '직접 입력',
        'naver.com',
        'empal.com',
        'empas.com',
        'gmail.com',
        'hanmail.net',
        'daum.net',
        'nate.com',
        'paran.com',
        'korea.com',
        'hitel.net',
        'unitel.co.kr',
        'kornet.net',
        'nownuri.net',
        'lycos.co.kr',
        'hanafos.com',
        'dreamwiz.com',
        'hanmir.com',
        'freechal.com',
        'chollian.net',
        'yahoo.co.kr',
        'hotmail.com',
      ],
    };
  },
  methods: {
    onChange(e) {
      const { name, value } = e.target;

      this.$emit('change', {
        name,
        value,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.email-input {
  display: flex;
  align-items: center;

  @include mq('tablet', 'max') {
    display: block;
  }

  &__at {
    width: 26px;
    text-align: center;
  }

  &__id,
  &__direct,
  &__select {
    padding: 0;
    text-indent: 14px;
    flex: 1 0 0;
  }

  &__select {
    appearance: auto;
    text-indent: 4px;
  }

  &__direct {
    margin-left: 10px;
  }
}
</style>
