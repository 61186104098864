
import Vue from 'vue';
import MicrosoftAdminInfoAfterTemplate from '@/templates/ms365/MicrosoftAdminInfoAfterTemplate.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import { getUserDetail, getHostingDetail, updateManagerInfo } from '@/api/proxy/gapi';
import { REGEX_PHONE_NUMBER, REGEX_MOBILE_NUMBER, REGEX_EMAIL } from '@/utils/regex';
import { getOperatingServiceInfo } from '@/api/proxy/service';

export default Vue.extend({
  components: {
    MicrosoftAdminInfoAfterTemplate,
  },
  props: {
    targetServiceInfo: {
      type: Object,
    },
  },
  data() {
    return {
      formTitle,
      isShowCompanyNameInput: true,
      // 서비스 정보
      serviceInfo: {
        carve_code_name: '',
        gtype_name: '',
        domain: '',
        service_seqno: -1,
      },
      formState: {
        companyName: '',
        // 소유자 정보
        owner: {
          id: '',
          name: '',
          email: '',
          phoneNumber: '',
          mobilePhoneNumber: '',
        },
        // 관리자 정보
        admin: {
          isSameOwner: false,
          name: '',
          email: {
            id: '',
            domain: '직접 입력',
            text: '',
          },
          phoneNumber: {
            num1: '02',
            num2: '',
            num3: '',
          },
          mobilePhoneNumber: {
            num1: '010',
            num2: '',
            num3: '',
          },
        },
      },
    };
  },
  async created() {
    const getUser = await getUserDetail();

    // 소유자 정보
    this.formState.owner = {
      id: getUser?.user_id || '',
      name: getUser?.register || '',
      email: getUser?.email || '',
      phoneNumber: getUser?.phone || '',
      mobilePhoneNumber: getUser?.hp_no || '',
    };

    // 서비스 정보
    const serviceSeq = this.targetServiceInfo.service_seqno;
    const getHosting = await getHostingDetail(serviceSeq);
    const getServiceInfo = await getOperatingServiceInfo(serviceSeq);

    // 운영중인 서비스가 있는 경우 - 관리 정보 입력 페이지에서 수정 방지 - 서비스 정보 페이지에서 수정해야함
    if (getServiceInfo) {
      location.href = `${process.env.VUE_APP_MY_GABIA_URL}/service#/?carve_code=msoffice365,office365`;
      return;
    }

    this.serviceInfo = {
      carve_code_name: this.targetServiceInfo?.carve_code_name || '',
      gtype_name: this.targetServiceInfo?.gtype_name || '',
      domain: getHosting?.domain || '',
      service_seqno: this.targetServiceInfo?.service_seqno || -1,
    };

    this.$console.log(getHosting, '서비스 정보');

    // 이전에 입력한 관리자 정보가 있는 경우
    if (getHosting?.b_hanname) {
      this.formState.admin.name = getHosting.b_hanname;
    }

    if (getHosting?.b_email) {
      const [id, text] = getHosting.b_email.split('@');
      this.formState.admin.email.id = id;
      this.formState.admin.email.text = text;
    }

    if (getHosting?.b_phone) {
      const [num1, num2, num3] = getHosting.b_phone.split('-');
      this.formState.admin.phoneNumber.num1 = num1;
      this.formState.admin.phoneNumber.num2 = num2;
      this.formState.admin.phoneNumber.num3 = num3;
    }

    if (getHosting?.b_mobile) {
      const [num1, num2, num3] = getHosting.b_mobile.split('-');
      this.formState.admin.mobilePhoneNumber.num1 = num1;
      this.formState.admin.mobilePhoneNumber.num2 = num2;
      this.formState.admin.mobilePhoneNumber.num3 = num3;
    }

    // 기업 회원인 경우
    if (this.$store.state.userInfo.user_type === 'organization') {
      this.formState.companyName = this.$store.state.userInfo.user_name
        .replace(/(?:\(|<).*?(?:\)|>)/g, '')
        .replace(/\s+/g, '')
        .replace(/[^0-9a-zA-Z가-힣]/g, '')
        .trim();
    }

    this.$GlobalLoading.hide();
  },
  methods: {
    async onSubmit() {
      const { companyName, admin } = this.formState;
      const { email, mobilePhoneNumber, name, phoneNumber, isSameOwner } = admin;

      const namePattern = /^[가-힣]{3,40}$/;
      const companyPattern = /^[a-zA-Z0-9가-힣]{2,20}$/;

      // 회사명
      if (this.isShowCompanyNameInput && companyName.length === 0) {
        alert('회사명은 필수 입력입니다.');
        return;
      }

      // 관리자명
      if (!namePattern.test(name)) {
        alert('관리자명은 3자 이상 한글만 입력해주세요. 영문자, 숫자, 공백 및 특수문자는 허용되지 않습니다.');
        return;
      }

      // 관리자명
      if (!companyPattern.test(companyName)) {
        alert('회사명은 2자 이상 입력해주세요. 공백 및 특수문자는 허용되지 않습니다. ');
        return;
      }

      // 이메일
      if (email.id.length === 0 || (email.domain === '직접 입력' && email.text.length === 0)) {
        alert('이메일은 필수 입력입니다.');
        return;
      }

      if (
        (email.domain !== '직접 입력' && !REGEX_EMAIL.test(email.id + '@' + email.domain)) ||
        (email.domain === '직접 입력' && !REGEX_EMAIL.test(email.id + '@' + email.text))
      ) {
        alert('잘못된 이메일 형식입니다.');
        return;
      }

      // 전화번호
      if (!REGEX_PHONE_NUMBER.test([phoneNumber.num1, phoneNumber.num2, phoneNumber.num3].join('-'))) {
        alert('잘못된 전화번호 형식입니다.');
        return;
      }

      // 휴대전화번호
      if (
        !REGEX_MOBILE_NUMBER.test([mobilePhoneNumber.num1, mobilePhoneNumber.num2, mobilePhoneNumber.num3].join('-'))
      ) {
        alert('잘못된 휴대전화 번호 형식입니다.');
        return;
      }

      this.$GlobalLoading.show();

      const params = {
        service_seq: this.serviceInfo.service_seqno,
        b_hanname: this.formState.admin.name,
        b_email:
          this.formState.admin.email.domain === '직접 입력'
            ? `${this.formState.admin.email.id}@${this.formState.admin.email.text}`
            : `${this.formState.admin.email.id}@${this.formState.admin.email.domain}`,
        b_phone: [
          this.formState.admin.phoneNumber.num1,
          this.formState.admin.phoneNumber.num2,
          this.formState.admin.phoneNumber.num3,
        ].join('-'),
        b_mobile: [
          this.formState.admin.mobilePhoneNumber.num1,
          this.formState.admin.mobilePhoneNumber.num2,
          this.formState.admin.mobilePhoneNumber.num3,
        ].join('-'),
        company_name: this.isShowCompanyNameInput ? companyName : '',
      };

      const managerInfo = await updateManagerInfo(params);

      if (!managerInfo?.isSuccess) {
        alert('관리자 정보가 정상 처리되지 않았습니다. 마이가비아에서 관리 정보를 입력하세요.');
      } else {
        alert('관리자 정보가 처리 완료되었습니다.');
      }

      this.$GlobalLoading.hide();
      location.href = `${process.env.VUE_APP_MY_GABIA_URL}/service#/?carve_code=msoffice365,office365`;
    },
  },
});
