/*
  신청 3단계 & 관리 정보 입력 페이지에서 사용되는 입력폼 관리
  - 관리자명
  - 이메일
  - 전화번호
  - 휴대전화
  의 입력 값을 변경하는 Mixins

  formState: {
    // 회사 이름
    companyName: '',
    // 관리자 정보
    admin: {
      isSameOwner: false,
      name: '',
      email: {
        id: '',
        domain: '직접 입력',
        text: '',
      },
      phoneNumber: {
        num1: '02',
        num2: '',
        num3: '',
      },
      mobilePhoneNumber: {
        num1: '010',
        num2: '',
        num3: '',
      },
    },
  },

  기본 사용 예제는 MS365 신청 3단계 & 관리 정보 입력 페이지 참고
 */
export default {
  methods: {
    // 회사 이름
    onChangeCompanyName(e) {
      const { value } = e.target;
      const LIMIT = 20;

      if (value.length > LIMIT) {
        this.$emit('change', {
          ...this.formState,
          companyName: '',
        });
        alert('회사명은 2 ~ 40자까지 입력할 수 있습니다. 공백 및 특수문자는 허용되지 않습니다.');
        return;
      } else {
        this.$emit('change', {
          ...this.formState,
          companyName: value,
        });
      }
    },
    // 소유자 정보와 같음
    onChangeIsSameOwner(e) {
      const { checked } = e;

      const ownerPhoneArr =
        this.formState.owner.phoneNumber.split('-').length === 3
          ? this.formState.owner.phoneNumber.split('-')
          : ['', '', ''];
      const ownerMobilePhoneArr =
        this.formState.owner.mobilePhoneNumber.split('-').length === 3
          ? this.formState.owner.mobilePhoneNumber.split('-')
          : ['', '', ''];

      this.$emit('change', {
        ...this.formState,
        admin: {
          ...this.formState.admin,
          name: this.formState.owner.name,
          mobilePhoneNumber: {
            num1: ownerMobilePhoneArr[0],
            num2: ownerMobilePhoneArr[1],
            num3: ownerMobilePhoneArr[2],
          },
          phoneNumber: {
            num1: ownerPhoneArr[0],
            num2: ownerPhoneArr[1],
            num3: ownerPhoneArr[2],
          },
          email: {
            domain: '직접 입력',
            id: this.formState.owner.email.split('@').length === 2 ? this.formState.owner.email.split('@')[0] : '',
            text: this.formState.owner.email.split('@').length === 2 ? this.formState.owner.email.split('@')[1] : '',
          },
          isSameOwner: checked,
        },
      });
    },
    // 관리자명
    onChangeAdminName(e) {
      const { value } = e.target;

      this.$emit('change', {
        ...this.formState,
        admin: {
          ...this.formState.admin,
          name: value,
        },
      });
    },
    // 이메일
    onChangeEmail(e) {
      const { name, value } = e;

      this.$emit('change', {
        ...this.formState,
        admin: {
          ...this.formState.admin,
          email: {
            ...this.formState.admin.email,
            [name]: value,
          },
        },
      });
    },
    // 전화번호
    onChangePhoneNumber(e) {
      const { name, value } = e;

      this.$emit('change', {
        ...this.formState,
        admin: {
          ...this.formState.admin,
          phoneNumber: {
            ...this.formState.admin.phoneNumber,
            [name]: value,
          },
        },
      });
    },
    // 휴대전화
    onChangeMobilePhoneNumber(e) {
      const { name, value } = e;

      this.$emit('change', {
        ...this.formState,
        admin: {
          ...this.formState.admin,
          mobilePhoneNumber: {
            ...this.formState.admin.mobilePhoneNumber,
            [name]: value,
          },
        },
      });
    },
  },
};
