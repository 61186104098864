<template>
  <div class="call-number-input">
    <select class="gt-input call-number-input__select" :value="formState.num1" @change="onInput" name="num1">
      <option v-for="item in getNumItems" :key="item" :value="item">{{ item }}</option>
    </select>
    <span class="call-number-input__separator">-</span>
    <input
      type="text"
      class="gt-input call-number-input__item"
      :value="formState.num2"
      @input="onInput"
      name="num2"
      maxlength="4"
    />
    <span class="call-number-input__separator">-</span>
    <input
      type="text"
      class="gt-input call-number-input__item"
      :value="formState.num3"
      @input="onInput"
      name="num3"
      maxlength="4"
    />
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'formState',
    event: 'change',
  },
  props: {
    formState: Object,
    numberType: {
      type: String,
      default: 'phone',
      validator: function (value) {
        return ['phone', 'areaCode', 'all'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    // @TODO 공통
    const phoneNumItems = ['010', '011', '016', '017', '018', '019'];

    const areaCodeNumItems = [
      '02',
      '031',
      '032',
      '033',
      '041',
      '042',
      '043',
      '044',
      '051',
      '052',
      '053',
      '054',
      '055',
      '061',
      '062',
      '063',
      '064',
      '070',
    ];

    const allNumItems = phoneNumItems.concat(areaCodeNumItems);

    return {
      phoneNumItems,
      areaCodeNumItems,
      allNumItems,
    };
  },
  computed: {
    getNumItems() {
      return this[`${this.numberType}NumItems`];
    },
  },
  methods: {
    onInput(e) {
      const { name, value } = e.target;
      this.$emit('change', { name, value: value.replace(/[^0-9]/g, '') });
    },
  },
});
</script>

<style lang="scss" scoped>
.call-number-input {
  display: flex;
  align-items: center;

  &__item,
  &__select {
    width: calc(33% - 8px);
  }

  &__separator {
    width: 16px;
    text-align: center;
  }

  &__select {
    appearance: auto;
  }
}
</style>
